.container-new-product {
  font-family: sans-serif;
  margin: 0 auto;
  .container-blog-new-product {
    width: 900px;
    margin: 0 auto;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.258);
    .blog-img-product {
      padding-top: 12px;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
    .image-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .red-asterisk {
      color: red !important;
    }
    .btn-new-product {
      margin-bottom: 20px !important;
    }
  }
  .label-custom-input {
    max-width: 140px;
  }
  .row {
    .edit-text-field {
      margin-top: 20px;
    }
  }
  .edit-text-field {
    width: 100%;
  }

  @media (max-width: 600px) {
    .container-blog-new-product {
      width: 100%;
    }
  }
}
.custum-alert {
  justify-content: center !important;
  width: 50% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  position: fixed !important;
}
@media (max-width: 768px) {
  .custum-alert {
    width: 100% !important;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}
.icon-clear-product {
  color: red;
}
.hide-dropdown-icon .MuiSelect-icon {
  display: none;
}
