.deltai-deltaicommission {
  font-family: sans-serif;
  font-weight: 500;
  font-size: 18px;
  .consaction-title {
    display: inline-block;
    margin-right: 20px;
  }
  .consaction-money {
    border-radius: 8px;
    padding: 5px 10px;
    font-weight: bold;
    color: #fff;
    background-color: #068f2f;
    font-size: 13px;
  }
  .status-new {
    border-radius: 8px;
    padding: 5px 10px;
    font-weight: bold;
    color: #fff;
    background-color: #0d6efd;
    font-size: 13px;
  }
  .status-done {
    border-radius: 8px;
    padding: 5px 10px;
    font-weight: bold;
    color: #fff;
    background-color: #06cd06;
    font-size: 13px;
  }
  .status-cancelled {
    border-radius: 8px;
    padding: 5px 10px;
    font-weight: bold;
    background-color: #ff0000;
    color: #fff;
    font-size: 13px;
  }
  .fullname-bold {
    font-weight: bold;
  }
  .popup-container {
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ccc;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;
    &:hover {
      box-shadow: 0 8px 12px rgba(228, 19, 19, 0.438);
    }
    .icon-close {
      position: absolute;
      top: 0;
      right: 0;
      width: 25px;
      height: 25px;
      background-color: #162938;
      font-size: 16px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom-left-radius: 10px;
      cursor: pointer;
      z-index: 1;
    }
  }
  .popup-buttons {
    // display: flex;
    // justify-content: space-between;
    // width: 100%;
    margin-top: 10px;
    .custum-btnmobile {
      margin: 5px !important;
    }
  }
  .popup button {
    flex: 1;
    margin: 5px;
  }
  .btn-transaction {
    margin: 10px;
  }
  .img-bill-mobile {
    cursor: pointer;
  }
  .modal {
    display: block;
    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    width: 100%;
    max-height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .relative__modal {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 36rem;
    height: 100%;
  }
  .close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    position: absolute;
    border-radius: 50%;
    background-color: #ffffff;
    top: 20px;
    right: 35px;
    color: red;
    font-size: 20px;
    transition: 0.3s;
    cursor: pointer;
    &:hover,
    &:focus {
      background-color: #ff0000;
      color: #ffffff;
      border: 1px solid #ff0000;
      text-decoration: none;
      cursor: pointer;
    }
  }
  @media (min-width: 1024px) {
    .relative__modal {
      max-width: 36rem /* 576px */;
    }
  }
  @media (max-width: 600px) {
    margin: 0 auto;
    font-size: 13px !important;
    .img-bill-mobile {
      max-width: 100% !important;
      height: auto;
    }
    .responsive-row {
      padding: 0 !important;
      .popup-container {
        border-radius: 8px;
        min-width: 80% !important;
        p {
          font-size: 16px;
        }
        .popup-buttons {
          justify-content: center !important;
        }
      }
    }
  }
}
