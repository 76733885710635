.container-form-login {
  display: flex;
  flex-direction: column;
  max-width: 350px !important;
  .custum-input-form-login {
    width: 100%;
  }
  .custum-input-form-login {
    width: 100%;
  }
  .container-checkbox {
    width: 100%;
  }
  .red-asterisk {
    color: red;
  }
  .error-message {
    color: red;
    margin-top: 10px;
    font-size: 14px;
  }
  .password-toggle-icon {
    cursor: pointer;
    color: #7d7878;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    margin: 0 !important;
  }
}
