.container-partner {
  font-family: sans-serif;
  margin: 0 auto;
  overflow-x: hidden;
  .btn-addparter {
    margin-right: auto;
    margin-bottom: 20px;
  }
  .h3 {
    margin: 20px 0 !important;
  }
  .btn-inactive,
  .btn-active {
    border-radius: 8px;
    padding: 5px 10px;
    font-weight: bold;
    color: #fff;
    font-size: 13px;
    border: none;
  }
  .btn-active {
    background-color: #06cd06;
  }
  .btn-inactive {
    background-color: #ff0000;
  }
  .error-search {
    color: #949191;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    margin: 0 !important;
  }
  @media (max-width: 600px) {
    .col-sm-4 {
      width: 200px !important;
      margin-bottom: 15px !important;
    }
    .custum-rpsive-user {
      display: flex;
    }
    .col-sm-2 {
      width: auto !important;
    }
  }
  @media (max-width: 768px) {
    font-size: 14px !important;
  }
  @media (max-width: 480px) {
    font-size: 12px !important;
    flex-direction: column !important;
  }
}
.table-prodcut {
  margin: 0 auto !important;
}
