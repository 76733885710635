.container-login-form {
  font-family: sans-serif;
  padding: 112px;
  margin: auto;
  @media (max-width: 600px) {
    .img-fluid {
      display: none;
    }
    .container-login-form {
      max-width: 100% !important;
      margin: 20px auto !important;
      padding: 20px auto !important;
      justify-content: center;
      text-align: center;
      align-items: center;
    }
  }
}
