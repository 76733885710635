.container-addpartner {
  font-family: sans-serif;
  .invalid-border {
    border: 1px solid red !important;
  }
  .red-asterisk {
    color: red;
  }
  .password-input-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    .password-check-icon {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      color: green;
    }
  }
  .error-message {
    color: red;
    font-size: 14px;
    margin-left: 10px;
  }

  .input-with-icon {
    position: relative;
  }

  .error-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: red;
    pointer-events: none;
  }
  .has-error {
    border-color: red;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    margin: 0 !important;
  }
  @media (max-width: 768px) {
    font-size: 14px !important;
  }
  @media (max-width: 480px) {
    font-size: 12px !important;
    flex-direction: column !important;
  }
  @media (max-width: 600px) {
    font-size: 13px;
    .id-card-mobile {
      max-width: 50% !important;
      display: flex !important;
      justify-content: center !important;
    }
  }
}
.alert-success {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #28a745;
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  z-index: 9999;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
