.container-create-popup {
  font-family: sans-serif;
  .custom-textfield {
    width: 100%;
  }
  // .success-alert {
  //   position: fixed;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  //   background-color: #3bc372;
  //   color: #ffffff;
  //   padding: 10px 20px;
  //   border-radius: 5px;
  //   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  //   z-index: 9999;
  // }
  @media (max-width: 768px) {
    font-size: 14px !important;
  }
  @media (max-width: 480px) {
    font-size: 12px !important;
    flex-direction: column !important;
  }
}
