.container-user {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  .btn-active {
    border-radius: 8px;
    padding: 5px 10px;
    font-weight: bold;
    color: #fff;
    background-color: #06cd06;
    font-size: 13px;
    border: none;
  }
  .btn-inactive {
    border-radius: 8px;
    padding: 5px 10px;
    font-weight: bold;
    background-color: #ff0000;
    color: #fff;
    font-size: 13px;
    border: none;
  }
  .h3 {
    margin: 20px 0 !important;
  }
  .status-button {
    border-radius: 8px !important;
    padding: 5px 10px !important;
    font-weight: bold !important;
    background-color: #ff0000 !important;
    color: #fff !important;
    font-size: 13px !important;
    border: none !important;
    text-align: center;
    align-items: center;
  }
  th,
  tr,
  td {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 10px 20px !important;
    vertical-align: middle;
    color: #333333;
  }
  .table-prodcut {
    margin: 0 auto !important;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    margin: 0 !important;
  }
  @media (max-width: 600px) {
    font-size: 13px !important;
    .col-sm-4 {
      width: 200px !important;
      margin-bottom: 15px !important;
    }
    .custum-rpsive-user {
      display: flex;
    }
    .col-sm-2 {
      width: auto !important;
    }
  }
  @media (max-width: 768px) {
    .table-prodcut {
      white-space: nowrap;
    }
    font-size: 14px !important;
  }
  @media (max-width: 480px) {
    font-size: 12px !important;
  }
  // @media (max-width: 1600px) {
  //   .col-sm-7 {
  //     width: 600px;
  //   }
  // }
}
