.container-update-workship {
  font-family: sans-serif;
  margin: 0 auto;
  .container-blog-new-offting {
    width: 800px;
    margin: 0 auto;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.258);
    // background: linear-gradient(160deg, #00ffff 0%, #ff6600 100%);
    // background: -webkit-linear-gradient(160deg, #00ffff 0%, #ff6600 100%);
    .blog-img-wordship {
      padding-top: 12px;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
    .image-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .red-asterisk {
      color: red;
    }
    .btn-new-offering {
      margin-bottom: 20px !important;
    }
  }
  // .label-custom-input {
  //   max-width: 140px;
  // }
  .row {
    .edit-text-field {
      margin-top: 20px;
    }
  }
  .edit-text-field {
    width: 100%;
  }
  @media (max-width: 600px) {
    .container-blog-new-offting {
      width: 100%;
    }
    .img-add-wordship {
      height: 300 !important;
    }
  }
}
