.sidebar-container {
  height: 100%;
  z-index: 999;
  .active {
    color: red;
  }
  display: block;
  .sidebar-inner {
    height: 100%;
    width: 200px;
    transition: width 0.2s;
    background-color: #ffffff;
    overflow-y: auto;
    transition: 0.25s ease;
    // .bar-sidebar {
    //   height: 100vh;
    //   overflow-y: auto;
    // }
    .side-inner-menu {
      list-style-type: none;
      padding: 0;
      margin: 0;
      .side-inner-menu-item {
        a {
          text-align: center;
          display: flex;
          align-items: center;
          text-decoration: none;
          color: #5d6166;
          .title {
            margin-left: 10px;
            font-size: 14px;
            font-weight: 500;
          }
          &:hover {
            color: #000000;
          }
        }
      }
    }
  }
  .is-collapsed {
    width: 60px !important;
    .side-inner-menu-item {
      display: flex;
      align-items: center;
      justify-content: center;
      justify-items: center;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        justify-items: center;
        .icon-holder {
          font-size: 18px;
        }
        .title {
          display: none;
        }
      }
    }
  }
  .icon-close {
    display: none;
  }
  .notifications-dropdown {
    display: none;
  }
  @media (max-width: 600px) {
    .icon-close {
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 30px;
      background-color: #ffa600;
      font-size: 18px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom-left-radius: 10px;
      cursor: pointer;
      z-index: 1;
    }
    .is-collapsed {
      display: none !important;
    }
    .sidebar-inner {
      max-width: 180px !important;
      background-color: #ffffff;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
      transition: box-shadow 0.3s ease;
      position: absolute;
      z-index: 2;
      position: fixed !important;
      &:hover {
        box-shadow: 0 8px 12px rgba(0, 0, 0, 0.185);
      }
      .container-icon-close .icon-close {
        display: block;
        color: #000000;
        margin: 10px;
        font-size: 22px;
        cursor: pointer;
      }
      .side-inner-menu {
        list-style-type: none;
        padding: 0;
        margin: 0;
        .side-inner-menu-item {
          a {
            text-align: center;
            display: flex;
            align-items: center;
            text-decoration: none;
            color: #949191 !important;
            .title {
              margin-left: 10px;
              font-size: 13px;
              font-weight: 500;
            }
            &:hover {
              color: #000000 !important;
            }
          }
        }
      }
    }
    .dropdown-toggle {
      position: absolute;
      background-color: #ffffff;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      .avatar-container {
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        .user-avatar {
          margin-right: 4px;
        }
        .user-fullname {
          font-size: 12px;
          font-weight: 500;
          color: #949191 !important;
          transition: 0.25s ease;
          &:hover {
            color: #000000 !important;
          }
        }
      }
    }
    .dropdown-toggle::after {
      display: none;
    }
    .dropdown-menu {
      position: absolute;
      width: 100% !important;
      left: auto;
      top: 64px;
      background-color: #ffffff;
      border-radius: 5px;
      padding: 10px 0;
      display: none;
      z-index: 1;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.3s ease;
      &:hover {
        box-shadow: 0 8px 12px rgba(255, 255, 255, 0.438);
      }
      .dropdown-item {
        color: #949191;
        font-size: 13px;
        &:hover {
          color: #ffffff;
          background-color: #008cff;
          font-weight: 500;
          border-radius: 10px;
        }
      }
      .side-icon {
        margin-right: 25px;
        font-size: 15px;
      }
    }
  }
  .sidebar-logoting {
    transition: all 0.3s ease-in-out;
    width: 100px;
    height: 100%;
    margin: 10px 0;
  }
  .sidebar-inner.is-collapsed .sidebar-logoting {
    width: 50px;
    height: 30px;
    margin: 10px auto;
    display: block;
    margin: 0 auto;
  }
}
