.container-statistical-page {
  font-family: sans-serif;
  .custom-textfield {
    width: 100%;
  }
  .col-sm-2 {
    width: auto !important;
  }

  .row {
    .col-sm-3 {
      width: 250px !important;
    }
    .total-revenue {
      cursor: pointer;
      margin: 10px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
      transition: box-shadow 0.3s ease;
      &:hover {
        box-shadow: 0 8px 12px rgba(228, 19, 19, 0.438);
      }
    }
    .h5 {
      font-size: 18px !important;
      font-weight: 500 !important;
    }
    .title-statistical {
      font-weight: 400 !important;
      margin: 0 !important;
      justify-content: center;
      font-size: 10px !important;
    }
    .total-ctv-deposits,
    .total-work-completed,
    .user-total-registration,
    .total-promotions,
    .total-revenue {
      height: 100px;
      border-radius: 10px;
    }
    .total-promotions,
    .statistical {
      background-color: rgb(132, 230, 166);
      color: rgb(43, 105, 65);
    }
    .user-total-registration {
      background-color: rgb(168, 233, 246);
      color: rgb(53, 99, 108);
    }
    .total-work-completed {
      background-color: rgb(231, 237, 169);
      color: rgb(122, 102, 69);
    }
    .total-ctv-deposits {
      background-color: rgb(236, 211, 171);
      color: rgb(122, 97, 56);
    }
  }
  .custum-button-mui .MuiButton-label {
    text-transform: math-auto !important;
  }
}
@media (max-width: 600px) {
  .btn-statistical {
    margin-bottom: 15px !important;
  }
  .form-search-date {
    display: flex;
    justify-content: center;
  }
}
