.container-commission {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
  .search-results {
    color: #949191;
  }
  .status-new {
    border-radius: 8px;
    padding: 5px 10px;
    font-weight: bold;
    color: #fff;
    background-color: #0d6efd;
    font-size: 13px;
  }
  .h3 {
    margin: 20px 0 !important;
  }
  .status-done {
    border-radius: 8px;
    padding: 5px 10px;
    font-weight: bold;
    color: #fff;
    background-color: #06cd06;
    font-size: 13px;
  }
  .status-cancelled {
    border-radius: 8px;
    padding: 5px 10px;
    font-weight: bold;
    background-color: #ff0000;
    color: #fff;
    font-size: 13px;
  }
  th,
  tr,
  td {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 10px 20px !important;
    vertical-align: middle;
    color: #333333;
  }
  .table-prodcut {
    margin: 0 auto !important;
  }
  .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    text-align: left !important;
  }
  @media (max-width: 600px) {
    .table {
      white-space: nowrap;
    }
    .custum-rpsive-transaction {
      margin-bottom: 15px !important;
    }
    .col-sm-2 {
      width: 50% !important;
      display: flex;
      justify-content: flex-start;
    }
    .custom-textfield {
      min-width: 160px !important;
    }
    .btn-outline-success {
      margin-left: 10px !important;
      height: 40px !important;
    }
  }
}
