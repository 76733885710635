.container-detailPartner {
  margin: auto;
  font-family: sans-serif;
  .error-message {
    color: red;
    font-size: 1rem;
    margin-top: 0.25rem;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .star-green {
    background-color: green;
    color: white;
  }

  .star-green input:focus {
    background-color: green !important;
    color: white;
  }

  .star-orange {
    background-color: orange;
    color: white;
  }

  .star-orange input:focus {
    background-color: orange !important;
    color: white;
  }

  .star-red {
    background-color: red;
    color: white;
  }

  .star-red input:focus {
    background-color: red !important;
    color: white;
    border: none;
  }
  .input-disabled {
    pointer-events: none !important;
  }
  .password-input-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    .password-check-icon {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      color: green;
    }
  }
  .custum-current_balance {
    font-weight: 500;
    color: red;
    border: none;
  }
  .custum-alert {
    justify-content: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    position: fixed !important;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    margin: 0 !important;
  }
  @media (max-width: 600px) {
    font-size: 13px;
    .col-sm-2 {
      width: 70% !important;
    }
    .col-sm-1 {
      width: 30% !important;
    }
    .id-card-mobile {
      max-width: 50% !important;
      display: flex !important;
      justify-content: center !important;
    }
  }
  @media (max-width: 768px) {
    font-size: 14px !important;
    .custum-alert {
      width: 100% !important;
    }
  }
  @media (max-width: 480px) {
    font-size: 12px !important;
    flex-direction: column !important;
  }
}
.form_deduction {
  width: 70% !important;
}
@media (max-width: 600px) {
  .form_deduction {
    width: 100% !important;
  }
}
