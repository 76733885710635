.container-listpromotion {
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: flex-start;
  // align-items: center;
  .btn-addprotomotion {
    margin-top: 20px;
    margin-right: auto;
    margin-bottom: 20px;
  }
  .row {
    flex-basis: 100%;
    display: flex;
    justify-content: left;
    .card-promotion {
      flex-basis: calc(33.33% - auto);
      margin-bottom: 20px;
      .card {
        border: 1px solid #ccc;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.3s ease;
        &:hover {
          box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
        }
        .card-img-top {
          width: 100%;
          height: 200px;
          object-fit: cover;
          border-radius: 8px 8px 0 0;
        }
        .card-body {
          padding: 8px;
          h4 {
            color: #262626 !important;
            font-size: 16px;
            // height: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 700;
          }
          .row {
            .lable-time {
              color: rgb(0, 94, 255);
              font-weight: 500;
            }
          }
          .btn-detail-promotion {
            text-decoration: none;
            font-weight: 600;
            text-align: left;
            color: #c2187d;
          }
          .btn-detail-promotion:hover {
            text-decoration: underline !important;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .row {
      justify-content: center;

      .card-promotion {
        flex-basis: calc(50% - 20px);
      }
    }
  }
  @media (max-width: 576px) {
    .row {
      justify-content: center;
      .card-promotion {
        flex-basis: 100%;
      }
    }
  }
  .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 100%;
  }
  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @media (max-width: 768px) {
    font-size: 14px !important;
  }
  @media (max-width: 480px) {
    font-size: 12px !important;
    flex-direction: column;
  }
  @media (max-width: 600px) {
    flex-direction: column !important;
    .time-mobile {
      display: flex !important;
      flex-direction: row !important;
      justify-content: space-between !important;
      flex-basis: 48% !important;
      margin-right: 4px !important;
    }
  }
}
