.container-promotion {
  .detail-image-item {
    position: relative;
    display: inline-block;
    margin: 5px;
  }
  .delete-btn {
    top: -10px;
    right: -10px;
    border-radius: 50%;
    position: absolute;
    padding: 2px 6px;
    font-size: 12px;
    border: none;
    background-color: red;
    color: white;
    display: none;
    cursor: pointer;
  }
  .detail-image-item:hover .delete-btn {
    display: block;
  }
  .detail-image-item.clicked .delete-btn {
    display: block;
  }
  .image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .detail-images-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .image-wrapper {
    width: 100px;
    height: 100px;
    overflow: hidden;
  }
  .detail-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
