.container-list-popup {
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: flex-start;
  // align-items: center;
  .btn-addpopup {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: auto;
  }
  .popup-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .popup-item {
      width: 100%;
      margin: 20px;
      box-sizing: border-box;

      @media (min-width: 768px) {
        width: calc(50% - 40px);
      }

      @media (min-width: 992px) {
        width: calc(33.33% - 40px);
      }
    }
  }
  .btn-detail-promotion {
    text-decoration: none;
    font-weight: 600;
    text-align: left;
    color: #c2187d;
    display: inline-block;
    position: relative;
    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-decoration: underline;
    }
    &:hover {
      text-decoration: underline !important;
    }
  }
  .card-img-wrapper {
    height: 300px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @media (max-width: 768px) {
    font-size: 14px !important;
  }
  @media (max-width: 480px) {
  }
  @media (max-width: 480px) {
    font-size: 12px !important;
    flex-direction: column !important;
  }
}
