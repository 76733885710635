.notification_container {
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;

  .red-asterisk {
    color: red;
  }
  .custum_container {
    background-color: #fbedd3;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.258);
    transition: box-shadow 0.3s ease;
    border-radius: 4px !important;
    max-width: 550px !important;
    .h3 {
      text-align: center;
      font-size: 20px;
      font-family: sans-serif;
      margin-bottom: 20px;
    }
  }
  .custum-alert {
    justify-content: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    position: fixed !important;
  }
}
@media (max-width: 600px) {
  .notification_container {
    padding: 80px 10px;
  }
}
