.container-detail-conditioner {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 10px 0;
  .columns-container {
    display: flex;
    justify-content: space-between;
  }
  .left-column,
  .right-column {
    padding: 10px;
    box-sizing: border-box;
  }
  .left-column {
    background-color: #efe6d6;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.258);
    transition: box-shadow 0.3s ease;
    border-radius: 4px !important;
  }
  .gas-column {
    margin: 0 auto;
  }
  .custum-job {
    font-size: 14px;
    font-weight: bold;
    max-width: 230px !important;
    // margin-right: 20px;
  }
  .custum-alert {
    justify-content: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    position: fixed !important;
  }
  .custum-job-data {
    font-weight: 400 !important;
  }
  .confirmation-dialog {
    .icon-close {
      position: absolute;
      top: 0;
      right: 0;
      width: 25px;
      height: 25px;
      background-color: #162938;
      font-size: 16px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom-left-radius: 10px;
      cursor: pointer;
      z-index: 1;
    }
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ccc;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;
    &:hover {
      box-shadow: 0 8px 12px rgba(228, 19, 19, 0.438);
    }
  }
  .confirmation-dialog p {
    margin-bottom: 10px;
  }
  .confirmation-dialog button {
    margin: 5px;
  }
  .partner-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .partner-info div {
    margin-bottom: 5px;
  }
  .status-new,
  .status-has-partner,
  .status-waiting,
  .status-doing,
  .status-done,
  .status-cancelled {
    border-radius: 8px;
    padding: 5px 10px;
    font-weight: bold;
    font-size: 13px;
    cursor: default;
  }
  .status-new {
    color: #fff;
    background-color: #0d6efd;
    border: 1px solid #0d6efd;
  }
  .status-has-partner {
    color: #0051ff;
    background-color: #c0cbe2;
    border: 1px solid #c0cbe2;
  }
  .status-waiting {
    color: #ffa500;
    background-color: #e4d7bf;
    border: 1px solid #e4d7bf;
  }
  .status-doing {
    color: #003dbf;
    background-color: #b2c4e9;
    border: 1px solid #b2c4e9;
  }
  .status-done {
    color: #fff;
    background-color: #06cd06;
    border: 1px solid #06cd06;
  }
  .status-cancelled {
    background-color: #ff0000;
    color: #fff;
    border: 1px solid #ff0000;
  }
  .send-require {
    margin-left: 20px !important;
  }
  @media (max-width: 768px) {
    font-size: 14px !important;
  }
  @media only screen and (max-width: 600px) {
    .modal-select {
      max-width: 60% !important;
      margin: 0 auto;
    }
    .modal-content {
      width: 60% !important;
    }
    .modal.show .modal-dialog {
      width: 60% !important;
    }
    .confirmation-dialog {
      min-width: 80% !important;
      border-radius: 8px !important;
      p {
        font-size: 13px !important;
      }
    }
    .custum-alert {
      width: 100% !important;
    }
    .modal-mobile {
      top: 20% !important;
    }
  }
}
@media only screen and (max-width: 600px) {
  .modal-content {
    margin-top: 100px;
    max-width: 80% !important;
    margin-left: auto;
    margin-right: auto;
  }
  .custom-partner-list {
    max-height: 40vh !important;
    overflow-y: auto !important;
  }
}
