.container-create-blog {
  font-family: sans-serif;
  .tox .tox-statusbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
  }
  font-family: sans-serif;
  .custom-textfield {
    width: 100% !important;
  }
  .image-container {
    text-align: center;
    // margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .d-grid.col-2 {
    margin-top: 20px;
  }
  .red-asterisk {
    color: red;
  }
  .tox.tox-tinymce {
    border: 1px solid #9f9595;
  }
  .tox.tox-tinymce:hover {
    border: 1px solid #000000;
  }
  .tox .tox-notification--warn, .tox .tox-notification--warning{
    display: none !important;
  }
  @media (max-width: 768px) {
    .d-grid.col-2 {
      margin-top: 35px !important;
    }
    .tox .tox-notification--warn, .tox .tox-notification--warning{
      display: none !important;
    }
  }
}
