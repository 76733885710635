.container-blog {
  font-family: sans-serif;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  .btn-add-blog {
    margin-left: 10px;
    margin-right: auto;
    margin-bottom: 20px;
  }
  .container-page.align-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .row {
    flex-basis: 100%;
    margin: 0 0 5px;
    display: flex;
    justify-content: left;
    .card-blog {
      flex-basis: calc(33.33% - 10px);
      margin-bottom: 20px;
      .card {
        border: 1px solid #ccc;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.3s ease;
        &:hover {
          box-shadow: 0 8px 12px rgba(228, 9, 9, 0.438);
        }
        .card-img-top {
          width: 100%;
          height: 200px;
          object-fit: cover;
          border-radius: 8px 8px 0 0;
        }
        .card-body {
          padding: 8px;
          a {
            text-decoration: none;
            &:hover {
              text-decoration: underline !important;
              text-underline-offset: 3px;
            }
            h4 {
              text-align: left;
              color: #008cff !important;
              font-size: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .row {
      justify-content: center;
      .card-blog {
        flex-basis: calc(50% - 20px);
      }
    }
  }

  @media (max-width: 576px) {
    .row {
      justify-content: center;
      .card-blog {
        flex-basis: 100%;
      }
    }
  }
  .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 100%;
  }
  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
