.label-custom-input {
  background-color: #ffffff;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  color: #236ee7;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
  border: 1px solid #236ee7;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  transition: 0.6s ease;
}
.label-custom-input::after {
  text-align: center;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.label-custom-input:hover {
  // background-color: #236ee7;
  background-color: #f3921a;
  border: 1px solid #f3921a;
  color: #ffffff;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
