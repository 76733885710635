@media (max-width: 600px) {
  .custum-rpsive-job {
    margin-bottom: 15px !important;
    display: flex;
    justify-content: left;
  }
  .col-sm-2 {
    max-width: 50%;
  }
  .col-sm-3 {
    max-width: 50%;
  }
  .custum-table-mobile {
    max-width: 350px !important;
  }
  .table-prodcut {
    white-space: nowrap;
  }
}
