.container-checkbox {
    margin: 0 auto;
    .ml-auto {
        float: right;
    }
    .caption {
        margin-left: 5px;
        cursor: pointer;
        color: #888;
        font-size: 14px;
    }
    .forgot-pass {
        position: relative;
        top: 2px;
        font-size: 14px;
    }
    a {
        color: #888;
        text-decoration: underline;
    }
    a:hover {
        text-decoration: none;
    }
}
