.container-page {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 20px 0 18px;
  flex-wrap: wrap;
  li {
    margin-top: 5px;
  }
  .active > .page-link,
  .page-link.active {
    background-color: #1b364e;
    border-color: #1b364e;
    &:hover {
      background-color: #1b364e !important;
      border-color: #1b364e !important;
    }
  }
  .page-link {
    transition: 0.4s ease !important;
  }
  .page-link:hover {
    background-color: #ffb700 !important;
    color: white !important;
  }
}
