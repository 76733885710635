.container-deltaiblog {
  font-family: sans-serif;
  .row {
    .custom-textfield {
      width: 100% !important;
    }
  }
  .tox .tox-statusbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
  }
  .red-asterisk {
    color: red;
  }
  .tox.tox-tinymce {
    border: 1px solid #9f9595;
  }
  .tox.tox-tinymce:hover {
    border: 1px solid #000000;
  }
  .tox .tox-notification--warn, .tox .tox-notification--warning{
    display: none !important;
  }
  // .tox-editor-header {
  //   border-bottom: 1px solid #9f9595;
  // }
  @media (max-width: 768px) {
    .tox .tox-notification--warn, .tox .tox-notification--warning{
      display: none !important;
    }
  }
}
