.container-tag-tffering {
  .nav-link {
    margin-right: 20px;
  }
  .nav-pills {
    .nav-item {
      .active {
        color: #fff;
        background-color: #ed2049;
        font-family: sans-serif;
        font-weight: 400;
      }
    }
  }
  .tag-offering {
    text-transform: uppercase;
    font-weight: 500;
    color: #000000;
  }
  .btn-addwordship {
    margin-left: auto;
    margin-bottom: 20px;
  }
  .additional-icons-container {
    position: absolute;
    top: 30%;
    left: -90px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: left;
    background-color: #ffffff;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.28);
    border-radius: 4px;
    padding: 2px 10px;
    &:hover {
      box-shadow: 0 8px 12px rgba(0, 0, 0, 0.386);
    }
  }
  .additional-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 5px;
    .btn-edit-worship {
      justify-content: center;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    a.btn-edit-worship,
    svg {
      margin-right: 6px;
      font-weight: 500;
    }
    &:hover {
      background-color: #bebebe;
      // padding: 2px 2px;
      text-align: center;
      border-radius: 3px;
    }
  }
  .btn-active {
    border-radius: 8px;
    padding: 5px 10px;
    font-weight: bold;
    color: #fff;
    background-color: #06cd06;
    font-size: 13px;
    border: none;
  }

  .btn-inactive {
    border-radius: 8px;
    padding: 5px 10px;
    font-weight: bold;
    background-color: #ff0000;
    color: #fff;
    font-size: 13px;
    border: none;
  }
  th,
  tr,
  td {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 10px 20px !important;
    vertical-align: middle;
    color: #333333;
    white-space: nowrap;
  }
  .table-prodcut {
    margin: 0 auto !important;
  }
  @media (max-width: 600px) {
    a {
      margin: auto !important;
      display: flex;
      width: 180px !important;
    }
    .additional-icons-container {
      position: absolute;
      top: 10%;
      left: -85px;
      transform: translateY(-50%);
      max-width: 110px !important;
    }
    .h3 {
      font-size: 16px !important;
      font-weight: 500 !important;
    }
    .btn-addprotomotion {
      width: 150px !important;
    }
    .tag-offering {
      text-decoration: underline;
      width: 50%;
    }
  }
}
