.container-review-list {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  .btn-vote-star {
    align-items: center;
    color: #fff;
    font-weight: bold;
    font-size: 13px;
  }
  .h3 {
    margin: 20px 0 !important;
  }
  .btn-vote-star svg {
    margin-left: 3px;
  }
  .green {
    background-color: rgb(40, 175, 40);
    border: 1px solid rgb(40, 175, 40);
    border-radius: 5px;
  }
  .yellow {
    background-color: rgb(255, 187, 0);
    border: 1px solid rgb(255, 187, 0);
    border-radius: 5px;
  }
  .red {
    background-color: rgb(255, 0, 0);
    border: 1px solid rgb(255, 0, 0);
    border-radius: 5px;
  }
  .container-review-vote {
    text-decoration: none;
    color: rgb(0, 119, 255);
  }
  .container-review-vote:hover {
    text-decoration: underline;
    color: rgb(26, 93, 169);
  }
  .custom-textfield {
    width: 100%;
  }
  .search-results {
    color: #949191;
  }
  .d-sm-table-cell {
    width: 350px;
  }
  th,
  tr,
  td {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 10px 20px !important;
    vertical-align: middle;
    color: #333333;
  }
  .table-prodcut {
    margin: 0 auto !important;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    margin: 0 !important;
  }
  @media (max-width: 600px) {
    .custum-filter-star {
      margin-bottom: 15px !important;
    }
    .col-sm-2 {
      width: 175px !important;
      display: flex;
    }
    .custum-create-at {
      width: 155px !important;
    }
    .col-sm-3 {
      display: flex;
    }
  }
}
