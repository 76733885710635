.container-navigation-page {
  padding: 10px 20px;
  background-color: #ffffff;
  border-bottom: 1px solid #d4d9db;
  width: 100%;
  height: 65px;
  .container-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nav-left {
      .nav-bar {
        cursor: pointer;
      }
    }
    .nav-right {
      display: flex;
      align-items: center;
      background-color: #ffffff;
      justify-content: center;
      .notifications-dropdown {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
      .dropdown-toggle {
        background-color: #ffffff;
        border: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        .avatar-container {
          display: flex;
          align-items: center;
          justify-content: center;
          .user-avatar {
            margin-right: 10px;
          }
          .user-fullname {
            font-size: 13px;
            font-weight: 500;
            color: #737378;
            transition: 0.25s ease;
            &:hover {
              color: #000000;
            }
          }
        }
      }
      .dropdown-toggle::after {
        display: none;
      }
      .dropdown-menu {
        position: absolute;
        min-width: 145px;
        right: auto !important;
        left: auto;
        top: 64px;
        background-color: #ffffff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 10px 0;
        display: none;
        z-index: 1;
        .dropdown-item {
          color: #737378;
          font-size: 14px;
          &:hover {
            background-color: #008cff;
            color: #ffffff;
            font-weight: 500;
            border-radius: 10px;
          }
        }
        .side-icon {
          margin-right: 15px;
        }
      }
      .dropdown-menu.show {
        display: block;
      }
      @media (max-width: 600px) {
        font-size: 13px !important;
        max-width: 100% !important;
        padding: 0 10px;
        overflow-x: auto;
        .nav-right {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-grow: 1;
          flex-direction: column;
        }
      }
    }
  }
}
