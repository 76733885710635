.flex-container {
    width: 100%;
    display: flex;
    padding: auto;
    margin: auto;
    height: auto;
    border-top: 1px solid #d4d9db;
    border-left: 1px solid #d4d9db;
    border-right: 1px solid #d4d9db;
}
.page-container {
    flex-grow: 1;
}
.sidebar-container {
    height: auto;
    border-right: 1px solid #d4d9db;
    transition: all 0.2s ease;
}
@media (min-width: 1200px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 100% !important;
    }
}
@media (max-width: 600px) {
    .sidebar-container {
        top: 0;
        position: absolute;
    }
    .flex-container {
        display: inline !important;
    }
    .page-container {
        width: 100%;
        position: absolute;
        top: 0;
    }
}
