.container-deltail-popup {
  font-family: sans-serif;
  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }
  .custom-textfield {
    width: 100% !important;
  }
  .spinner-border {
    width: 3rem;
    height: 3rem;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border 0.75s linear infinite;
  }
  @keyframes spinner-border {
    to {
      transform: rotate(360deg);
    }
  }
  @media (max-width: 768px) {
    font-size: 14px !important;
  }
  @media (max-width: 480px) {
    font-size: 12px !important;
    flex-direction: column !important;
  }
}
