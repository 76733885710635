.container-detail-user {
  font-family: sans-serif;
  .update-textfield {
    width: 100%;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    margin: 0 !important;
  }
  .custum-alert {
    position: fixed !important;
  }
  .container-user-ting {
    border-radius: 10px;
    width: 700px;
    padding: 10px;
    margin: 30px auto;
    justify-content: center;
    background-color: #efe6d6;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  .custum-detail-user {
    margin-left: 15px !important;
    font-weight: bold !important;
  }
  @media (max-width: 600px) {
    font-size: 13px !important;
    .container-user-ting {
      margin: 10px auto;
      width: 95% !important;
    }
  }
}
.form-switch .form-check-input:checked {
  cursor: pointer !important;
}
.form-switch .form-check-input {
  cursor: pointer !important;
}
