.container-list-air {
  max-width: 100%;
  margin: 0 auto;
  font-family: sans-serif;
  .custum-date-job {
    width: 100% !important;
  }
  .h3 {
    margin: 20px 0 !important;
  }
  .status-new,
  .status-has-partner,
  .status-waiting,
  .status-doing,
  .status-done,
  .status-cancelled {
    border-radius: 8px;
    padding: 3px 10px;
    font-weight: bold;
    font-size: 13px;
    cursor: default;
  }
  .status-new {
    color: #fff;
    background-color: #0d6efd;
    border: 1px solid #0d6efd;
  }
  .status-has-partner {
    color: #0051ff;
    background-color: #c0cbe2;
    border: 1px solid #c0cbe2;
  }
  .status-waiting {
    color: #ffa500;
    background-color: #e4d7bf;
    border: 1px solid #e4d7bf;
  }
  .status-doing {
    color: #003dbf;
    background-color: #b2c4e9;
    border: 1px solid #b2c4e9;
  }
  .status-done {
    color: #fff;
    background-color: #06cd06;
    border: 1px solid #06cd06;
  }
  .status-cancelled {
    background-color: #ff0000;
    color: #fff;
    border: 1px solid #ff0000;
  }
  .custum-td-job:hover {
    text-decoration: none;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    margin: 0 !important;
  }
  .MuiSvgIcon-root,
  .MuiSvgIcon-fontSizeMedium,
  .MuiSelect-icon,
  .MuiSelect-iconOutlined,
  .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    &::after {
      content: none !important;
    }

    .MuiSelect-selectMenu {
      padding-right: 0 !important;
    }
  }
  @media (max-width: 600px) {
    font-size: 13px !important;
    max-width: 100% !important;
    .custum-rpsive-job {
      margin-bottom: 15px !important;
      display: flex;
      justify-content: left;
    }
    .col-sm-2 {
      max-width: 50%;
    }
    .col-sm-3 {
      max-width: 50%;
    }
    .custum-table-mobile {
      max-width: 350px !important;
    }
    .table {
      white-space: nowrap;
    }
  }
  .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    text-align: left !important;
  }
  .hide-dropdown-icon .MuiSelect-icon {
    display: none;
  }
  .icon-clear-status {
    color: #e84343;
    &:hover {
      color: #ff0000;
    }
  }
  th,
  tr,
  td {
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 10px 20px !important;
    vertical-align: middle;
    color: #333333;
  }
  .table-prodcut {
    margin: 0 auto !important;
  }
}
.search-error {
  justify-content: center;
  position: fixed !important;
}
