.container-detail-review {
  font-family: sans-serif;
  .edit-textfield {
    width: 70%;
  }
  .custom-text-field .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  .custom-text-field .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
  .custom-text-field .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  .image-gallery {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .gallery-image {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
    margin-right: 20px;
    transition: transform 0.5s ease;
  }
  .zoomed-image {
    transform: scale(1.5);
    transform-origin: center center;
    z-index: 1;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 200%;
    height: 200%;
    margin: 0;
    transform: translate(-50%, -50%);
    object-fit: contain;
  }
}
